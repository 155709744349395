.monitorNav {
    width: 304px;
    position: absolute;
    left: 0;
    top: 0;
}

.monitorNav h1 {
    margin: 0 0 16px 0;
    padding: 15px 0;
    background-image: url('/monitor/button_big_unchecked.png');
    background-size: 100% 100%;
    text-align: center;
    font-size: 25px;
    font-weight: 500;
    color: rgba(255, 255, 255, 1);
    line-height: 38px;
}

.monitorNav h1.checked{
    background-image: url('/monitor/button_big.png');
}

.monitorNav a {
    color: rgba(255, 255, 255, 1);
    text-decoration: none;
}

.monitorNav .download_wall {
    /* width: 100%; */
    height: 204px;
    background-image: url('/monitor/download_wall_bg.png');
    background-size: 100% 100%;
    padding: 6px 12px;
}

.monitorNav .download_wall h4 {
    font-size: 20px;
    font-weight: 500;
    color: rgba(255, 255, 255, 1);
    line-height: 30px;
    text-align: center;
    margin: 0;
}

.monitorNav .download_wall h4 img {
    width: 20px;
    height: 20px;
    vertical-align: -4px;
}

.monitorNav .download_wall .download {
    background-image: url('/monitor/button_grey.png');
    background-size: 100% 100%;
    width: 280px;
    height: 68px;
    display: inline-block;
    text-align: center;
    font-size: 20px;
    font-weight: 500;
    color: rgba(51, 51, 51, 1);
    line-height: 68px;
    margin: 15px 0 0;
}